import React, { useState } from "react";
import "../Modules/ExcelExport.css";
import img_icon from "../res/close.png";
import { Method } from "../MethodUtils";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { RequestToGetBillByDate } from "../HTTPRequest";
export default function ExcelExport({ onRequestToClose }) {
  const [startDate, setStartDate] = useState("0000-00-00");
  const [customName, setCustomName] = useState("");
  const [endDate, setEndDate] = useState(() => {
    return Method.TodaysDate();
  });

  function reConstructData(array) {
    var newArr = [];
    array.map((data, index) => {
      var total =
        parseInt(data.FREIGHT) + parseInt(data.COMM) + parseInt(data.LABOUR);
      var json = {
        "Customer Name": data.CNAME,
        "Bill No": data.BILLNO,
        Date: Method.ConvertISOToYYYYMMDD(data.bdate),
        GMR: data.GMR,
        "Bill Type": data.billtype,
        "Station From": data.SF,
        Transport: data.TN,
        package: data.package,
        "Motor And Rly Freight": data.FREIGHT,
        Commission: data.COMM,
        Labor: data.LABOUR,
        "Total Amount": data.TAMT || total,
        ST: data.ST,
        Other: data.OTHER,
      };
      newArr.push(json);
    });
    return newArr;
  }
  function requestToGenerateExcel() {
    RequestToGetBillByDate(startDate, endDate, (data) => {
      exportToExcel(reConstructData(data), customName, startDate, endDate);
    });
  }

  return (
    <div className="excel_export_parent">
      <div className="excel_export_content">
        <div className="excel_export_title">
          <div>Export</div>
          <img
            src={img_icon}
            alt=""
            className="excel_export_close_button"
            onClick={() => onRequestToClose && onRequestToClose()}
          ></img>
        </div>
        <div className="excel_export_description">
          Export data in Excel Sheet
        </div>

        <div className="select_data_range">
          <DateRangeSelector
            text={"Start Date"}
            value={startDate}
            onSelected={(v) => setStartDate(v)}
            maxDate={endDate}
          ></DateRangeSelector>
          <DateRangeSelector
            text={"End Date"}
            value={endDate}
            onSelected={(v) => setEndDate(v)}
            minDate={startDate}
            maxDate={Method.TodaysDate()}
          ></DateRangeSelector>
        </div>
        <input
          className="custom_file_name"
          placeholder="Custom sheet name - optional"
          value={customName}
          onChange={(e) => setCustomName(e.target.value)}
        ></input>
        <div className="excel_export_button_parent">
          <button
            className="excel_export_button"
            onClick={requestToGenerateExcel}
          >
            Export
          </button>
        </div>
      </div>
    </div>
  );
}

function DateRangeSelector({ text, value, onSelected, minDate, maxDate }) {
  function OnValueUpdate(newDate) {
    onSelected && onSelected(newDate);
  }
  return (
    <div className="excel_export_select_type_parent">
      <div className="excel_export_select_type_name">{text}</div>
      <input
        type="date"
        className="excel_export_select_type_value"
        onChange={(e) => OnValueUpdate(e.target.value)}
        value={value}
        min={minDate || "0000-00-00"}
        max={maxDate || "0000-00-00"}
      ></input>
    </div>
  );
}

const exportToExcel = (temp_array, customName, startDate, endDate) => {
  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert the array of objects to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(temp_array);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(
    workbook,
    worksheet,
    customName || "export-sheet"
  );

  // Convert the workbook to a binary string
  const excelBinaryString = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "binary",
  });

  // Create a Blob from the binary string
  const blob = new Blob([s2ab(excelBinaryString)], {
    type: "application/octet-stream",
  });

  // Save the file using FileSaver.js
  var fileName = `export-bill ${startDate}_${endDate}.xlsx`;
  if (customName) {
    fileName = customName + ".xlsx";
  }
  saveAs(blob, fileName);
};

// Helper function to convert binary string to array buffer
function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
}
