import { Display } from "./Modules/DisplayMessage";

const KEY_END_DATE = "key_end_date";
const KEY_START_DATE = "key_start_date";

const KEY_DEVELOPER_OPTION = "is_developer_option_enable";
const KEY_DEVELOPER_LOG = "is_developer_log_enable";
const KEY_DEVELOPER_WARNING = "is_developer_warning_enable";
const KEY_DEVELOPER_ERROR = "is_developer_error_enable";

const KEY_GLOBAL_BILL_HEADER_FOOTER_PADDING = "global_header_footer_padding";
const KEY_GLOBAL_BILL_PADDING = "global_content_bill_padding_top_bottom";
const KEY_GLOBAL_BILL_BORDER_SIZE = "global_bill_border_size";
const BILL_TYPE_KEY = {
  CASH_MEMO: {
    KEY: "Cash Memo",
    VALUE: "GHINDUMAL & SONS",
  },
  ESTIMATE: {
    KEY: "Estimate",
    VALUE: "Estimate",
  },
};
const END_DATE_TYPE = {
  NONE: 1,
  TODAY: 2,
  START_DATE: 3,
};

const Method = {
  IsStringValid: (str) => {
    return Method_IsValidString(str);
  },
  TodaysDate: () => {
    return Method_TodaysDate();
  },
  FilterStringArray: (arr, value) => {
    return Method_FilterStringArray(arr, value);
  },
  ConvertISOToYYYYMMDD: (isoDate) => {
    return Method_ConvertISOToYYYYMMDD(isoDate);
  },
  GenerateDummyArray: (length, arraySize) => {
    return Method_GenerateDummyArray(length, arraySize);
  },
  SetDeveloperOption: (value) => {
    Method_EnableDeveloperLogOptions(KEY_DEVELOPER_OPTION, value);
  },
  SetDeveloperLogOption: (value) => {
    Method_EnableDeveloperLogOptions(KEY_DEVELOPER_LOG, value);
  },
  SetDeveloperWarnOption: (value) => {
    Method_EnableDeveloperLogOptions(KEY_DEVELOPER_WARNING, value);
  },
  SetDeveloperErrorOption: (value) => {
    Method_EnableDeveloperLogOptions(KEY_DEVELOPER_ERROR, value);
  },
  IsDeveloperOptionEnable: () => {
    return Method_RequestToGetLocalStorageBool(KEY_DEVELOPER_OPTION, false);
  },
  IsDeveloperLogEnable: () => {
    if (!Method_RequestToGetLocalStorageBool(KEY_DEVELOPER_OPTION, false)) {
      return false;
    }
    return Method_RequestToGetLocalStorageBool(KEY_DEVELOPER_LOG, false);
  },
  IsDeveloperWarnEnable: () => {
    if (!Method_RequestToGetLocalStorageBool(KEY_DEVELOPER_OPTION, false)) {
      return false;
    }
    return Method_RequestToGetLocalStorageBool(KEY_DEVELOPER_WARNING, false);
  },
  IsDeveloperErrorEnable: () => {
    if (!Method_RequestToGetLocalStorageBool(KEY_DEVELOPER_OPTION, false)) {
      return false;
    }
    return Method_RequestToGetLocalStorageBool(KEY_DEVELOPER_ERROR, false);
  },
  GetStartDate: () => {
    return Method_GetStartDate();
  },
  SetStartDate: (new_date) => {
    Method_SetStartDate(new_date);
  },
  SetEndDateType: (type) => {
    Method_SetEndDateType(type);
  },
  GetEndDateType: () => {
    return Method_GetEndDateType();
  },
  GetGlobalBillPadding() {
    return localStorage.getItem(KEY_GLOBAL_BILL_PADDING, 30);
  },
  SetGlobalBillPadding(value) {
    localStorage.setItem(KEY_GLOBAL_BILL_PADDING, value);
  },
  GetGlobalTopBottomBillPadding() {
    return localStorage.getItem(KEY_GLOBAL_BILL_HEADER_FOOTER_PADDING, 5);
  },
  SetGlobalTopBottomBillPadding(value) {
    localStorage.setItem(KEY_GLOBAL_BILL_HEADER_FOOTER_PADDING, value);
  },
  GetGlobalBillBorder() {
    return localStorage.getItem(KEY_GLOBAL_BILL_BORDER_SIZE, 1);
  },
  SetGlobalBillBorder(value) {
    localStorage.setItem(KEY_GLOBAL_BILL_BORDER_SIZE, value);
  },
  FindAndUpdateArrayOfStrings(array, value, replaceValue) {
    return Method_FindAndUpdateArrayOfStrings(array, value, replaceValue);
  },
  FindAndRemoveArrayOfStrings(array, removeValue) {
    return Method_FindAndRemoveArrayOfStrings(array, removeValue);
  },
};
function Method_FindAndRemoveArrayOfStrings(array, removeValue) {
  const index = array.indexOf(removeValue);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
}

function Method_FindAndUpdateArrayOfStrings(array, value, replaceValue) {
  for (var i = 0; i < array.length; i++) {
    if (array[i] === value) {
      array[i] = replaceValue;
      console.log(`${array[i]} === ${value} is ${array[i] === value}`);
    }
  }
  return array;
}

function Method_SetEndDateType(type) {
  localStorage.setItem(KEY_END_DATE, type);
}
function Method_GetEndDateType() {
  const defaultDate = END_DATE_TYPE.NONE;
  return parseInt(localStorage.getItem(KEY_END_DATE)) || defaultDate;
}

function Method_GetStartDate() {
  const defaultDate = "1995-01-01";
  const date = localStorage.getItem(KEY_START_DATE);
  return date || defaultDate;
}

function Method_SetStartDate(new_date) {
  localStorage.setItem(KEY_START_DATE, new_date);
}

function Method_EnableDeveloperLogOptions(key, __enable) {
  localStorage.setItem(key, __enable);
  Display.Warning("Request To Update LocalStorage : " + key + " - " + __enable);
}
function Method_RequestToGetLocalStorageBool(key, defaultValue) {
  const storedValue = localStorage.getItem(key);
  return storedValue
    ? JSON.parse(storedValue)
    : defaultValue
    ? defaultValue
    : false;
}

const Method_ConvertISOToYYYYMMDD = (isoDate) => {
  const dateObj = new Date(isoDate);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const Method_FilterStringArray = (arr, value) => {
  if (!arr || !value) {
    return arr;
  }
  return arr.filter((item) =>
    item.toLowerCase().startsWith(value.toLowerCase())
  );
};

const Method_TodaysDate = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  var yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};
const Method_IsValidString = (str) => {
  if (!str) {
    return false;
  }
  if (str === "" || str === " ") {
    return false;
  }
  return true;
};
function Method_GenerateDummyArray(length, arraySize) {
  const alphabet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // Characters to choose from
  const result = []; // Array to store dummy strings

  for (let i = 0; i < arraySize; i++) {
    let dummyString = ""; // Variable to store generated string

    for (let j = 0; j < length; j++) {
      const randomIndex = Math.floor(Math.random() * alphabet.length); // Generate random index to choose character
      dummyString += alphabet[randomIndex]; // Append randomly chosen character to dummy string
    }

    result.push(dummyString); // Push generated string to result array
  }

  return result;
}
export { Method, END_DATE_TYPE, BILL_TYPE_KEY };
