import React, { useEffect, useState } from "react";
import "../Modules/DisplayMessage.css";
import { Method } from "../MethodUtils";

const Display = {
  Log: null,
  Warning: null,
  Error: null,
};

function DisplayMessage() {
  const [messageList, setMessageList] = useState([]);
  const [warningList, setWarningList] = useState([]);
  const [errorList, setErrorList] = useState([]);

  const displayLog = (message) => {
    if (!Method.IsDeveloperLogEnable()) {
      setMessageList([]);
      return;
    }
    setMessageList((prevMessageList) => [...prevMessageList, message]);
    console.log(message);
  };

  const displayWarning = (warning) => {
    if (!Method.IsDeveloperWarnEnable()) {
        setWarningList([]);
      return;
    }
    setWarningList((prevWarningList) => [...prevWarningList, warning]);
  };

  const displayError = (error) => {
    if (!Method.IsDeveloperErrorEnable()) {
        setErrorList([]);
      return;
    }
    setErrorList((prevErrorList) => [...prevErrorList, error]);
  };

  useEffect(() => {
    const clearMessage = setTimeout(() => {
      setMessageList((prevMessageList) => prevMessageList.slice(1));
    }, 5000);

    const clearWarning = setTimeout(() => {
      setWarningList((prevWarningList) => prevWarningList.slice(1));
    }, 5000);

    const clearError = setTimeout(() => {
      setErrorList((prevErrorList) => prevErrorList.slice(1));
    }, 5000);

    return () => {
      clearTimeout(clearMessage);
      clearTimeout(clearWarning);
      clearTimeout(clearError);
    };
  }, [messageList, warningList, errorList]);

  Display.Log = displayLog;
  Display.Warning = displayWarning;
  Display.Error = displayError;

  return (
    <div className="display_message_container">
      {messageList &&
        messageList.map((option, index) => (
          <div key={index} className="display_message_log">
            <pre>{JSON.stringify(option, null, 2)}</pre>
          </div>
        ))}

      {warningList &&
        warningList.map((option, index) => (
          <div key={index} className="display_message_warning">
            <pre>{JSON.stringify(option, null, 2)}</pre>
          </div>
        ))}

      {errorList &&
        errorList.map((option, index) => (
          <div key={index} className="display_message_error">
            <pre>{JSON.stringify(option, null, 2)}</pre>
          </div>
        ))}
    </div>
  );
}
export default DisplayMessage;
export { Display };
