import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";





import "../src/res/fonts/Poppins/Poppins-Black.ttf";
import "../src/res/fonts/Poppins/Poppins-Bold.ttf";
import "../src/res/fonts/Poppins/Poppins-ExtraBold.ttf";
import "../src/res/fonts/Poppins/Poppins-ExtraLight.ttf";
import "../src/res/fonts/Poppins/Poppins-Light.ttf";
import "../src/res/fonts/Poppins/Poppins-Medium.ttf";
import "../src/res/fonts/Poppins/Poppins-Regular.ttf";
import "../src/res/fonts/Poppins/Poppins-SemiBold.ttf";
import "../src/res/fonts/Poppins/Poppins-Thin.ttf";

const routePages = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },

]);
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={routePages} />




  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
