import "./BillManagement.css";
import SearchIcon from "../res/search.png";
import CloseIcon from "../res/close.png";
import DeleteGrp from "../res/delete_grp.png";
import EditGrp from "../res/edit_grp.png";
import PrintGrp from "../res/print_grp.png";
import "../constant.css";
import { React, useState, useRef, useEffect } from "react";
import {
  CLIENT_TABLE,
  RequestToAddSingleEntries,
  RequestToCreateBill,
  RequestToDeleteBill,
  RequestToGetFilterBill,
  RequestToUpdateBill,
  STATION_TABLE,
  TRANSPORT_TABLE,
} from "../HTTPRequest";
import { BILL_TYPE_KEY, END_DATE_TYPE, Method } from "../MethodUtils";
import { CustomInput, GenerateConfirmationPopup } from "../App";
import BillOutputModule from "./BillOutputModule";
import SaveData from "../SaveData";

var billList = [];
var SchemaBill = {
  bill_no: 0,
  name: "",
  date: Method.TodaysDate(),
  gr_no: "",
  from_station: "",
  to_transport: "",
  no_of_package: 0,
  motor_and_rly_freight: 0,
  labor_charges: 0,
  commission: 0,
};
var DummyBill = {
  bill_no: 78958,
  name: "Shizuka Nobisuke",
  date: Method.TodaysDate(),
  gr_no: "GR_87945985545",
  from_station: "Jaipur",
  to_transport: "Tokyo",
  no_of_package: 10,
  motor_and_rly_freight: 1299,
  labor_charges: 4999,
  commission: 7899,
};
const temp_array = [DummyBill, DummyBill, DummyBill, DummyBill];
var dummy_filter = {
  bill_no: "",
  name: "",
  date: "",
  gr_no: "",
  from_station: "",
  to_transport: "",
  billtype: "None",
};

function BillManagement({ OnBackgroundEventCalled }) {
  const [bills, setBills] = useState(billList);
  const [saveBill, setSaveBill] = useState(null);
  const [IsCreatingBill, setCreatingBill] = useState(false);
  const [BillLimit, setBillLimit] = useState(500);
  const [BillOffset, setBillOffset] = useState(1);
  const [filterLimitDropDown, setFilterLimitDropDown] = useState(null);
  const [temp_filter_json] = useState({});

  const RequestToGetBillLimit = (e) => {
    setBillLimit(parseInt(e.target.value));
    setBillOffset(1);
    const temp = temp_filter_json;
    temp["limit"] = parseInt(e.target.value);
    temp["offset"] = 1;
    Wait_OnBillFilter(temp);
  };
  const RequestToGetBillOffset = (e) => {
    setBillOffset(parseInt(e.target.value));
    const temp = temp_filter_json;
    temp["offset"] = parseInt(e.target.value);
    Wait_OnBillFilter(temp);
  };

  const SaveBill = (_bill, createBill) => {
    if (_bill.bill_no === 0) {
      return;
    }
    console.log("L:::: ", _bill);

    if (createBill) {
      RequestToCreateBill(
        _bill,
        (resp) => {
          OnBillFilter();
          SaveData.IncreaseEntryCount();
        },
        (error) => console.error(error)
      );

      return;
    }

    RequestToUpdateBill(
      _bill,
      (response) => OnBillFilter(),
      (error) => console.error("Failed To Update Bill : " + error)
    );
  };

  const handleDelete = (index) => {
    const updatedBills = [...bills];
    const requestBill = updatedBills[index];
    GenerateConfirmationPopup(
      "Bill Delete!",
      "Are you sure you want to delete bill " + requestBill["name"],
      () => {
        console.log("[ ConfirmPopup ] Accept");
        RequestToDeleteBill(
          requestBill["bill_no"],
          (success) => OnBillFilter(),
          (error) => console.error("Failed To Delete Bill : " + error)
        );
      },
      () => console.log("[ ConfirmPopup ] Reject")
    );
  };

  const OnClickAddBill = () => {
    OnBackgroundEventCalled(true);
    setCreatingBill(true);
  };

  const OnRequestToClose = () => {
    OnBackgroundEventCalled(false);
    setCreatingBill(false);
    setSaveBill(null);
  };

  const OnRequestToPrintAndSave = (data) => {
    OnBackgroundEventCalled(false);
    setCreatingBill(false);
    SaveBill(data);
  };

  const OnRequestToSave = (data, IsNewEntry) => {
    OnBackgroundEventCalled(false);
    setCreatingBill(false);
    SaveBill(data, IsNewEntry);
    setSaveBill(null);
  };

  const RequestToOpenSaveBill = (billData) => {
    setSaveBill(billData);
    setCreatingBill(true);
  };
  const RequestToPrintBill = (billData) => {
    var newBill = billData;
    newBill["print_bill"] = true;
    RequestToOpenSaveBill(billData);
  };

  const OnBillFilter = (temp_filter) => {
    const __timer = setTimeout(() => {
      Wait_OnBillFilter(temp_filter);
    }, 5);
    return () => clearTimeout(__timer);
  };

  const Wait_OnBillFilter = (temp_filter) => {
    if (!temp_filter) {
      temp_filter = {};
    }
    if (temp_filter["bill_no"] || temp_filter["bill_no"] === "") {
      temp_filter_json["bill_no"] = temp_filter["bill_no"];
    }
    if (temp_filter["name"] || temp_filter["name"] === "") {
      temp_filter_json["name"] = temp_filter["name"];
    }
    if (temp_filter["date"] || temp_filter["date"] === "") {
      temp_filter_json["date"] = temp_filter["date"];
    }
    if (temp_filter["gr_no"] || temp_filter["gr_no"] === "") {
      temp_filter_json["gr_no"] = temp_filter["gr_no"];
    }
    if (temp_filter["from_station"] || temp_filter["from_station"] === "") {
      temp_filter_json["from_station"] = temp_filter["from_station"];
    }
    if (temp_filter["to_transport"] || temp_filter["to_transport"] === "") {
      temp_filter_json["to_transport"] = temp_filter["to_transport"];
    }
    if (temp_filter["billtype"] || temp_filter["billtype"] === "") {
      temp_filter_json["billtype"] = temp_filter["billtype"];
    }
    let __limit = BillLimit;
    let __offset = (BillOffset - 1) * BillLimit;

    if (temp_filter["limit"]) {
      __limit = temp_filter["limit"];
    }
    if (temp_filter["offset"] && temp_filter["limit"]) {
      __offset = (temp_filter["offset"] - 1) * temp_filter["limit"];
    }
    temp_filter_json["limit"] = __limit;
    temp_filter_json["offset"] = __offset;

    RequestToGetFilterBill(temp_filter_json, (resp, err) => {
      if (err || !resp) {
        console.error("Failed to Fetch Bill Filter", err);
        const newBill = [];
        setBills(newBill);
        return;
      }
      const newBill = [];
      resp?.data.forEach((n) => {
        newBill.push(n);
      });
      const __limit =
        resp["total_count"] / temp_filter_json["limit"] + 1 || BillLimit + 1;
      const options = [];
      for (let i = 1; i <= __limit; i++) {
        options.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
      setFilterLimitDropDown(options);
      setBills(newBill);
      // console.log(
      //   "Filtered Bills",
      //   resp["total_count"],
      //   temp_filter_json["limit"] + 1
      // );
    });
  };

  return (
    <div className="bill_management_parent">
      {/* <DeleteBill></DeleteBill> */}
      {IsCreatingBill && (
        <CreateBillLocal
          OnCreateBillClose={OnRequestToClose}
          OnClickSave={OnRequestToSave}
          OnClickPrintAndSave={OnRequestToPrintAndSave}
          LoadBillData={saveBill}
        ></CreateBillLocal>
      )}
      <div className="bill_management_title">Bill Management</div>
      <div className="bill_management_description_parent">
        <div className="bill_management_description">
          Create Edit or delete bills
        </div>
        <div
          style={{
            display: "flex",
            height: "55px",
            alignItems: "end",
          }}
        >
          {/* DropDown */}
          <div
            style={{
              width: "300px",
              marginRight: "20px",
              display: "flex",
            }}
          >
            {/* Page NO */}
            <div
              style={{
                width: "100%",
                marginRight: "20px",
              }}
            >
              <div style={{ fontSize: "0.5rem" }}>Page No</div>
              <select
                style={{
                  width: "100%",
                  marginRight: "20px",
                  height: "30px",
                }}
                onChange={RequestToGetBillOffset}
              >
                {filterLimitDropDown && filterLimitDropDown}
              </select>
            </div>

            {/* Per Page Limit */}
            <div
              style={{
                width: "100%",
                marginRight: "20px",
              }}
            >
              <div style={{ fontSize: "0.5rem" }}>Limit</div>
              <select
                style={{
                  width: "100%",
                  marginRight: "20px",
                  height: "30px",
                }}
                onChange={RequestToGetBillLimit}
              >
                <option value="500">500</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="3000">3000</option>
              </select>
            </div>
          </div>

          {/* Add Bill */}
          <div
            className="bill_management_add_new_bill_button"
            onClick={OnClickAddBill}
          >
            + Add Bill
          </div>
        </div>
      </div>
      <BillTable
        billList={bills}
        onDelete={handleDelete}
        LoadBill={RequestToOpenSaveBill}
        OnPrintBill={RequestToPrintBill}
        OnFilterValueUpdate={OnBillFilter}
        OnBillLimitUpdate={BillLimit}
        OnBillOffsetUpdate={BillOffset}
      />
    </div>
  );
}

export default BillManagement;

//TODO: Delete Bill

//TODO: Create Bill
const CreateBillLocal = ({
  OnCreateBillClose,
  OnClickPrintAndSave,
  OnClickSave,
  LoadBillData,
}) => {
  var IsNewBill = true;
  if (LoadBillData) {
    IsNewBill = false;
  }
  // LoadBillData = DummyBill;
  const [billData, setBillData] = useState({
    billtype: LoadBillData?.billtype || BILL_TYPE_KEY.CASH_MEMO.VALUE,
    bill_no: LoadBillData?.bill_no || SaveData.GetEntryCount(),
    name: LoadBillData?.name || "",
    date: LoadBillData?.date
      ? Method.ConvertISOToYYYYMMDD(LoadBillData.date)
      : Method.TodaysDate(),
    gr_no: LoadBillData?.gr_no || "",
    from_station: LoadBillData?.from_station || "",
    to_transport: LoadBillData?.to_transport || "",
    no_of_package: LoadBillData?.no_of_package || 0,
    motor_and_rly_freight: LoadBillData?.motor_and_rly_freight || 0,
    labor_charges: LoadBillData?.labor_charges || 0,
    commission: LoadBillData?.commission || 0,
    print_bill: LoadBillData?.print_bill || false,
  });

  const printRef = useRef(null);
  useEffect(() => {
    const printIfFound = setTimeout(() => {
      if (LoadBillData?.print_bill) {
        handlePrint();
      }
    }, 10);
    return () => clearTimeout(printIfFound);
  });
  const handlePrint = async () => {
    if (!printRef) {
      return;
    }
    if (!printRef.current) {
      return;
    }
    if (!printRef.current.innerHTML) {
      return;
    }
    OnClickSave(billData, IsNewBill);
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print Bill</title>");
    printWindow.document.write("</head><body>");
    printWindow.document.write(printRef?.current?.innerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    const intervalId = setInterval(() => {
      console.log(printWindow);
      printWindow.close();
      if (printWindow.closed) {
        clearInterval(intervalId);
      }
    }, 10);
    printWindow.print();
  };

  const handleInputChange = (field, value) => {
    // console.log("handleInputChange : [field,", field, "] [", field, value, "]");
    setBillData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const OnRequestToSave = (billData, IsNewBill) => {
    if (!Method.IsStringValid(billData.name)) {
      console.log("name is not valid");
      return;
    }
    if (billData.no_of_package <= 0) {
      console.log("no_of_package is not valid");
      return;
    }
    OnClickSave(billData, IsNewBill);
  };
  customCtrlSFunction(() => {
    if (billData) OnClickSave(billData, IsNewBill);
  });
  customCtrlPFunction(handlePrint);
  customESCFunction(() => {
    OnCreateBillClose && OnCreateBillClose();
  });

  const [suggestionListClientList, updateClientSuggestionList] = useState(() =>
    SaveData.GetClientList()
  );
  const [suggestionListTransportList, updateTransportSuggestionList] = useState(
    () => SaveData.GetTransportList()
  );
  const [suggestionListStationList, updateStationSuggestionList] = useState(
    () => SaveData.GetStationList()
  );
  const Callback_OnClickSaveButtonName = () => {
    RequestToAddSingleEntries(
      `INSERT INTO ${CLIENT_TABLE.tableId} (${CLIENT_TABLE.row.table}) VALUE('${billData.name}')`,
      (resp, error) => {
        if (error) {
          console.log(error);
          return;
        }
        SaveData.updateClientList(billData.name);
        updateClientSuggestionList(SaveData.GetClientList());
      }
    );
  };

  const Callback_OnClickSaveButtonTransport = () => {
    RequestToAddSingleEntries(
      `INSERT INTO ${TRANSPORT_TABLE.tableId} (${TRANSPORT_TABLE.row.table}) VALUE('${billData.to_transport}')`,
      (resp, error) => {
        if (error) {
          console.log(error);
          return;
        }
        SaveData.updateTransportList(billData.to_transport);
        updateTransportSuggestionList(SaveData.GetTransportList());
      }
    );
  };

  const Callback_OnClickSaveButtonStation = () => {
    RequestToAddSingleEntries(
      `INSERT INTO ${STATION_TABLE.tableId} (${STATION_TABLE.row.table}) VALUE('${billData.from_station}')`,
      (resp, error) => {
        if (error) {
          console.log(error);
          return;
        }
        SaveData.updateStationList(billData.from_station);
        updateStationSuggestionList(SaveData.GetStationList());
      }
    );
  };

  return (
    <div className="create_bill_parent">
      <div className="create_bill_title">Create Bill</div>
      <div className="create_bill_title_underline"></div>
      <div className="create_bill_input_output_parent">
        <div className="create_bill_input">
          {/* Name - Date */}
          <BillInputField
            value_1={billData?.date}
            placeholder_name_1={"Date"}
            input_type_1={"date"}
            onValueChangeEvent_1={(value) => handleInputChange("date", value)}
            pattern_1={"d{2}/d{2}/d{4}"}
            suggestion_2={suggestionListClientList}
            value_2={billData?.name}
            placeholder_name_2={"Name"}
            input_type_2={"name"}
            onValueChangeEvent_2={(value) => handleInputChange("name", value)}
            OnClickSaveButton_2={Callback_OnClickSaveButtonName}
            isSuggestionsRequired_2={true}
            defaultFocus_2={true}
          ></BillInputField>

          {/* From Station - Transport */}
          <BillInputField
            value_1={billData?.from_station}
            placeholder_name_1={"From Station"}
            input_type_1={"text"}
            onValueChangeEvent_1={(value) =>
              handleInputChange("from_station", value)
            }
            value_2={billData?.to_transport}
            placeholder_name_2={"Transport"}
            input_type_2={"text"}
            onValueChangeEvent_2={(value) =>
              handleInputChange("to_transport", value)
            }
            suggestion_1={suggestionListStationList}
            suggestion_2={suggestionListTransportList}
            OnClickSaveButton_1={Callback_OnClickSaveButtonStation}
            OnClickSaveButton_2={Callback_OnClickSaveButtonTransport}
            isSuggestionsRequired_1={true}
            isSuggestionsRequired_2={true}
          ></BillInputField>

          {/* Bill No. - Gr. No. */}
          <BillInputField
            value_1={billData?.bill_no}
            placeholder_name_1={"Bill No."}
            input_type_1={"number"}
            onValueChangeEvent_1={(value) =>
              handleInputChange("bill_no", value)
            }
            custom_css_1={{ backgroundColor: "#E30022" }}
            read_only_1={true}
            value_2={billData?.gr_no}
            placeholder_name_2={"Gr. No."}
            input_type_2={"text"}
            onValueChangeEvent_2={(value) => handleInputChange("gr_no", value)}
          ></BillInputField>

          {/* No. of packages - Motor & Rly. Freight */}
          <BillInputField
            value_1={billData?.no_of_package}
            placeholder_name_1={"No. of packages"}
            input_type_1={"text"}
            onValueChangeEvent_1={(value) =>
              handleInputChange("no_of_package", value)
            }
            value_2={billData?.motor_and_rly_freight}
            placeholder_name_2={"Motor & Rly. Freight"}
            input_type_2={"number"}
            onValueChangeEvent_2={(value) =>
              handleInputChange("motor_and_rly_freight", value)
            }
          ></BillInputField>

          {/* Labor charges - Commission */}
          <BillInputField
            value_1={billData?.commission}
            placeholder_name_1={"Commission"}
            input_type_1={"number"}
            onValueChangeEvent_1={(value) =>
              handleInputChange("commission", value)
            }
            value_2={billData?.labor_charges}
            placeholder_name_2={"Labour charges"}
            input_type_2={"number"}
            onValueChangeEvent_2={(value) =>
              handleInputChange("labor_charges", value)
            }
          ></BillInputField>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              marginTop: "40px",
              height: "30px",
            }}
          >
            <select
              style={{
                height: "100%",
              }}
              id="dropdown"
              value={
                billData?.billtype === BILL_TYPE_KEY.ESTIMATE.KEY
                  ? BILL_TYPE_KEY.ESTIMATE.VALUE
                  : BILL_TYPE_KEY.CASH_MEMO.VALUE
              }
              onChange={(t) => {
                // if (LoadBillData) LoadBillData["billtype"] = t.target.value;
                // billData["billtype"] = t.target.value;
                console.log(t.target.value);
                handleInputChange("billtype", t.target.value);
              }}
            >
              <option
                value={BILL_TYPE_KEY.CASH_MEMO.KEY}
                style={{ paddingTop: "50px" }}
              >
                {BILL_TYPE_KEY.CASH_MEMO.VALUE}
              </option>
              <option
                value={BILL_TYPE_KEY.ESTIMATE.VALUE}
                style={{ paddingTop: "50px" }}
              >
                Estimate
              </option>
            </select>
          </div>
          <div
            className="create_bill_create_options"
            style={{ marginTop: "20px" }}
          >
            <button className="create_bill_cancel" onClick={OnCreateBillClose}>
              Cancel
            </button>
            <button
              className="create_bill_save"
              onClick={() => OnRequestToSave(billData, IsNewBill)}
            >
              Save
            </button>
            <button
              className="create_bill_print_and_save"
              onClick={() => handlePrint()}
            >
              Print & Save
            </button>
          </div>
        </div>
        <div
          className="create_bill_output"
          style={{
            alignItems: "start",
          }}
        >
          <BillOutputModule
            billDataProps={billData}
            outputRef={printRef}
          ></BillOutputModule>
        </div>
      </div>
    </div>
  );
};

const BillInputField = ({
  value_1,
  placeholder_name_1,
  onValueChangeEvent_1,
  input_type_1,
  custom_css_1,
  read_only_1,
  pattern_1,
  customSaveOnServer_1,
  suggestion_1,
  OnClickSaveButton_1,
  isSuggestionsRequired_1,
  defaultFocus_1,
  //TODO: Option 2
  suggestion_2,
  value_2,
  placeholder_name_2,
  onValueChangeEvent_2,
  input_type_2,
  custom_css_2,
  read_only_2,
  pattern_2,
  customSaveOnServer_2,
  OnClickSaveButton_2,
  isSuggestionsRequired_2,
  defaultFocus_2,
}) => {
  const override_css = {
    width: "47%",
    height: "35px",
    marginTop: "35px",
  };
  const merge_json = [
    {
      j_value: value_1,
      j_placeholder: placeholder_name_1,
      j_onValueChangeEvent: onValueChangeEvent_1,
      j_input_type: input_type_1,
      j_custom_css: custom_css_1,
      j_read_only: read_only_1,
      j_pattern: pattern_1,
      j_customSaveOnServer: customSaveOnServer_1,
      j_suggestion: suggestion_1,
      j_onClickSaveButton: OnClickSaveButton_1,
      j_isSuggestionsRequired: isSuggestionsRequired_1 || false,
      j_defaultFocus: defaultFocus_1 || false,
    },
    {
      j_value: value_2,
      j_placeholder: placeholder_name_2,
      j_onValueChangeEvent: onValueChangeEvent_2,
      j_input_type: input_type_2,
      j_custom_css: custom_css_2,
      j_read_only: read_only_2,
      j_pattern: pattern_2,
      j_customSaveOnServer: customSaveOnServer_2,
      j_suggestion: suggestion_2,
      j_onClickSaveButton: OnClickSaveButton_2,
      j_isSuggestionsRequired: isSuggestionsRequired_2 || false,
      j_defaultFocus: defaultFocus_2 || false,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      {merge_json &&
        merge_json.map((option, index) => (
          <CustomInput
            key={index}
            _value={option.j_value}
            custom_css={override_css}
            _placeholder={option.j_placeholder}
            _onValueChange={option.j_onValueChangeEvent}
            inputType={option.j_input_type}
            suggestions={option.j_suggestion}
            _readOnly={option.j_read_only}
            _saveButton={option.j_customSaveOnServer}
            OnClickSaveButton={option.j_onClickSaveButton}
            _pattern={option.j_pattern}
            isSuggestionRequired={option.j_isSuggestionsRequired}
            defaultFocus={option.j_defaultFocus}
          ></CustomInput>
        ))}
    </div>
  );
};

//TODO: Bill table
const BillTable = ({
  billList,
  onDelete,
  LoadBill,
  OnPrintBill,
  OnFilterValueUpdate,
}) => {
  const handleDelete = (index) => {
    onDelete(index);
  };
  return (
    <div className="bill_table_parent">
      <table className="bill_table_parent_table">
        <thead className="bill_table_parent_table_thead">
          <ShowBillTitle
            OnBillFilterUpdate={OnFilterValueUpdate}
          ></ShowBillTitle>
        </thead>
        <tbody>
          {billList &&
            billList.map((bill, index) => (
              <tr key={index} style={{}}>
                <td className="table_data">{bill.name}</td>
                <td className="table_data">{bill.bill_no}</td>
                <td className="table_data">{bill.gr_no}</td>
                <td className="table_data">{bill.no_of_package}</td>
                <td className="table_data">
                  {Method.ConvertISOToYYYYMMDD(bill.date)}
                </td>
                <td className="table_data">{bill.from_station}</td>
                <td className="table_data">{bill.to_transport}</td>
                <td className="table_data">{bill.motor_and_rly_freight}</td>
                <td className="table_data">{bill.commission}</td>
                <td className="table_data">{bill.labor_charges}</td>
                <td className="table_data">
                  {parseInt(bill.commission) +
                    parseInt(bill.labor_charges) +
                    parseInt(bill.motor_and_rly_freight) +
                    ""}
                </td>
                <td className="table_data">
                  {bill.billtype === BILL_TYPE_KEY.ESTIMATE.VALUE
                    ? BILL_TYPE_KEY.ESTIMATE.VALUE
                    : "Cash Memo"}
                </td>
                <td className="table_data">
                  <img
                    src={EditGrp}
                    alt=""
                    className="table_data_options"
                    onClick={() => LoadBill(bill)}
                  ></img>
                  <img
                    src={PrintGrp}
                    alt=""
                    className="table_data_options"
                    onClick={() => {
                      OnPrintBill(bill);
                    }}
                  ></img>
                  <img
                    src={DeleteGrp}
                    alt=""
                    className="table_data_options"
                    onClick={() => handleDelete(index)}
                  ></img>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

const ShowBillTitle = ({
  OnBillFilterUpdate,
  OnBillLimitUpdate,
  OnBillOffsetUpdate,
}) => {
  const [filter, setFilter] = useState(dummy_filter);
  const [startDate, setStartDate] = useState(() => {
    return Method.GetStartDate();
  });
  const [endDate, setEndDate] = useState(() => {
    const type = Method.GetEndDateType();
    if (type === END_DATE_TYPE.NONE) {
      return "0000-00-00";
    }
    if (type === END_DATE_TYPE.TODAY) {
      return Method.TodaysDate();
    }
    if (type === END_DATE_TYPE.START_DATE) {
      return Method.TodaysDate();
    }
  });

  const ResetDate = () => {
    setStartDate(Method.GetStartDate());
    const type = Method.GetEndDateType();
    if (type === END_DATE_TYPE.NONE) {
      setEndDate("0000-00-00");
      return;
    }
    setEndDate(Method.TodaysDate());
  };

  const handleChangeName = (value) => {
    setFilter((prevFilter) => ({ ...prevFilter, name: value })); // Update name using functional update
  };
  const handleChangeBillNo = (value) => {
    setFilter((prevFilter) => ({ ...prevFilter, bill_no: value })); // Update name using functional update
  };

  const handleChangeGrNo = (value) => {
    setFilter((prevFilter) => ({ ...prevFilter, gr_no: value })); // Update name using functional update
  };
  const handleChangeFromStation = (value) => {
    setFilter((prevFilter) => ({ ...prevFilter, from_station: value })); // Update name using functional update
  };
  const handleChangeToStation = (value) => {
    setFilter((prevFilter) => ({ ...prevFilter, to_transport: value })); // Update name using functional update
  };
  const handleChangeBillType = (value) => {
    setFilter((prevFilter) => ({ ...prevFilter, billtype: value })); // Update name using functional update
  };
  const OnDateFilterUpdateStartDate = (value) => {
    const start_date_filter = value;
    var end_date_filter = endDate;
    setStartDate(value);
    const type = Method.GetEndDateType();
    if (type === END_DATE_TYPE.TODAY) {
      setEndDate(Method.TodaysDate());
      end_date_filter = Method.TodaysDate();
    }
    if (type === END_DATE_TYPE.START_DATE) {
      setEndDate(value);
      end_date_filter = value;
    }

    if (endDate === "0000-00-00") {
      setFilter((prevFilter) => ({ ...prevFilter, date: start_date_filter }));
      return;
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      date: start_date_filter + "," + end_date_filter,
    }));
  };
  const OnDateFilterUpdateEndDate = (value) => {
    const start_date_filter = startDate;
    const end_date_filter = value;
    setEndDate(value);

    if (endDate === "0000-00-00") {
      setFilter((prevFilter) => ({ ...prevFilter, date: start_date_filter }));
      return;
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      date: start_date_filter + "," + end_date_filter,
    }));
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      OnBillFilterUpdate && OnBillFilterUpdate(filter);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [filter, OnBillLimitUpdate, OnBillOffsetUpdate]);
  return (
    <tr className="table_title_parent">
      <CreteTableDataRow
        title_name="Name"
        search_icon={SearchIcon}
        close_icon={CloseIcon}
        OnValueChange={(value) => handleChangeName(value)}
        defaultFocus={true}
      ></CreteTableDataRow>

      <CreteTableDataRow
        title_name="Bill No"
        search_icon={SearchIcon}
        close_icon={CloseIcon}
        OnValueChange={(value) => handleChangeBillNo(value)}
      ></CreteTableDataRow>

      <CreteTableDataRow
        title_name="GR No"
        search_icon={SearchIcon}
        close_icon={CloseIcon}
        OnValueChange={(value) => handleChangeGrNo(value)}
      ></CreteTableDataRow>
      <th
        className="table_data"
        style={{
          fontWeight: "lighter",
          fontSize: "0.8rem",
          textAlign: "center",
        }}
      >
        Package
      </th>
      <th className="table_data">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "100%" }}>
            <div
              style={{ width: "100%", fontSize: "0.6rem", textAlign: "center" }}
            >
              Start Date
            </div>
            <input
              type="date"
              value={startDate}
              onChange={(e) => {
                OnDateFilterUpdateStartDate(e.target.value);
              }}
            ></input>
          </div>
          <div style={{ width: "100%" }}>
            <div
              style={{ width: "100%", fontSize: "0.6rem", textAlign: "center" }}
            >
              End Date
            </div>
            <input
              type="date"
              value={endDate}
              onChange={(e) => {
                OnDateFilterUpdateEndDate(e.target.value);
              }}
              min={startDate}
            ></input>
          </div>

          <img
            src={CloseIcon}
            alt="icon"
            className="close_icon"
            onClick={ResetDate}
          ></img>
        </div>
      </th>
      <CreteTableDataRow
        title_name="From"
        search_icon={SearchIcon}
        close_icon={CloseIcon}
        OnValueChange={(value) => handleChangeFromStation(value)}
      ></CreteTableDataRow>
      <CreteTableDataRow
        title_name="Transport"
        search_icon={SearchIcon}
        close_icon={CloseIcon}
        OnValueChange={(value) => handleChangeToStation(value)}
      ></CreteTableDataRow>

      <th
        className="table_data"
        style={{
          fontWeight: "lighter",
          fontSize: "0.8rem",
          textAlign: "center",
        }}
      >
        Freight
      </th>
      <th
        className="table_data"
        style={{
          fontWeight: "lighter",
          fontSize: "0.8rem",
          textAlign: "center",
        }}
      >
        Commission
      </th>
      <th
        className="table_data"
        style={{
          fontWeight: "lighter",
          fontSize: "0.8rem",
          textAlign: "center",
        }}
      >
        Labor
      </th>
      <th
        className="table_data"
        style={{
          fontWeight: "lighter",
          fontSize: "0.8rem",
          textAlign: "center",
        }}
      >
        Total
      </th>
      <th
        className="table_data"
        style={{
          fontWeight: "lighter",
          fontSize: "0.8rem",
        }}
      >
        <select
          style={{
            width: "100%",
            marginRight: "20px",
            height: "30px",
          }}
          onChange={(e) => {
            handleChangeBillType(e.target.value);
          }}
        >
          <option value="None">None</option>
          <option value={BILL_TYPE_KEY.CASH_MEMO.KEY}>
            {BILL_TYPE_KEY.CASH_MEMO.KEY}
          </option>
          <option value="Estimate">Estimate</option>
        </select>
      </th>
      <th>Options</th>
    </tr>
  );
};

const CreteTableDataRow = ({
  title_name,
  search_icon,
  close_icon,
  OnValueChange,
  defaultFocus,
}) => {
  const filterInputRef = useRef(null);
  const [__defaultFocus, setDefaultFocus] = useState(defaultFocus);

  const ClearValue = () => {
    if (filterInputRef) {
      filterInputRef.current.value = "";
      OnValueChange && OnValueChange("");
    }
  };
  useEffect(() => {
    if (!__defaultFocus && filterInputRef) {
      return;
    }
    const timer = setTimeout(() => {
      filterInputRef?.current?.focus();
      setDefaultFocus(false);
    }, 0);
    return () => clearTimeout(timer);
  });
  return (
    <th className="table_data">
      <div className="input_container">
        {search_icon && (
          <img src={search_icon} alt="search_icon" className="search_icon" />
        )}
        <input
          ref={filterInputRef}
          className="search_input_filter"
          placeholder={title_name}
          onChange={(v) => {
            OnValueChange && OnValueChange(v.target.value);
          }}
        />
        {close_icon && (
          <img
            src={close_icon}
            alt="close_icon"
            className="close_icon"
            onClick={ClearValue}
          />
        )}
      </div>
    </th>
  );
};

var customPrintFunctionCallback;
function customCtrlPFunction(customCallback) {
  customPrintFunctionCallback = customCallback;
}
document.addEventListener("keydown", function (event) {
  if (event.ctrlKey && (event.key === "p" || event.key === "P")) {
    event.preventDefault();
    customPrintFunctionCallback && customPrintFunctionCallback();
  }
});

var customSaveFunctionCallback;
function customCtrlSFunction(customCallback) {
  customSaveFunctionCallback = customCallback;
}
document.addEventListener("keydown", function (event) {
  if (event.ctrlKey && (event.key === "s" || event.key === "S")) {
    event.preventDefault();
    customSaveFunctionCallback && customSaveFunctionCallback();
  }
});

var customEscFunctionCallback;
function customESCFunction(customCallback) {
  customEscFunctionCallback = customCallback;
}
document.addEventListener("keydown", function (event) {
  if (event.key === "Escape") {
    event.preventDefault();
    customEscFunctionCallback && customEscFunctionCallback();
  }
});
