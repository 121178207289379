import { React, useState } from "react";
import SaveModule from "./SaveModule";
import { Method } from "../MethodUtils";
import SaveData from "../SaveData";
import { RequestToUpdateSingleEntries, STATION_TABLE } from "../HTTPRequest";
function SaveFrom() {
  const [saveList, setSaveList] = useState(() => {
    return SaveData.GetStationList();
  });
  return (
    <SaveModule
      title={"Station"}
      content_data={saveList}
      OnUpdate={(oldValue, newValue, onSuccess) => {
        console.log(oldValue, newValue);
        RequestToUpdateSingleEntries(
          `UPDATE ${STATION_TABLE.tableId} SET ${STATION_TABLE.row.table} = '${newValue}' WHERE ${STATION_TABLE.row.table} = '${oldValue}' `,
          (resp, error) => {
            if (error) {
              console.error(error);
              return;
            }
            const newArr = Method.FindAndUpdateArrayOfStrings(
              saveList,
              oldValue,
              newValue
            );
            setSaveList(newArr);
            onSuccess && onSuccess();
          }
        );
      }}
      OnDelete={(deleteValue, onSuccess) => {
        RequestToUpdateSingleEntries(
          `DELETE FROM ${STATION_TABLE.tableId} WHERE ${STATION_TABLE.row.table} = '${deleteValue}' `,
          (resp, error) => {
            if (error) {
              console.error(error);
              return;
            }
            const newArr = Method.FindAndRemoveArrayOfStrings(
              saveList,
              deleteValue
            );
            setSaveList(newArr);
            onSuccess && onSuccess(newArr);
          }
        );
      }}
    ></SaveModule>
  );
}

export default SaveFrom;
