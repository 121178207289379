var __clientList = [];
var __transportList = [];
var __stationList = [];
var __entryCount = 0;
const SaveData = {
  GetClientList: () => __clientList,
  GetTransportList: () => __transportList,
  GetStationList: () => __stationList,
  
  CreateClientList: (newClientArr) => (__clientList = newClientArr),
  CreateTransportList: (newTransportArr) => (__transportList = newTransportArr),
  CreateStationList: (newStationArr) => (__stationList = newStationArr),

  updateClientList: (newClient) => __clientList.push(newClient),
  updateTransportList: (newTransport) => __transportList.push(newTransport),
  updateStationList: (newStation) => __stationList.push(newStation),

  GetEntryCount: () => __entryCount,
  CreateEntryCount: (newBillNo) => (__entryCount = newBillNo),
  IncreaseEntryCount: () => {
    __entryCount++;
  },
};

export default SaveData;
