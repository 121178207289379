import React, { useEffect, useState } from "react";
import "./BillOutputModule.css";
import { BILL_TYPE_KEY, Method } from "../MethodUtils";

var GLOBAL_PADDING = "5px";
var GLOBAL_FONT_FAMILY = "Arial, Helvetica, sans-serif";
var GLOBAL_DATA_BOX_HEIGHT = "30px";
var GLOBAL_LINE = "1px solid black";

export default function BillOutputModule({ billDataProps, outputRef }) {
  useEffect(() => {
    GLOBAL_PADDING = (Method.GetGlobalTopBottomBillPadding() || 5) + "px";
    GLOBAL_DATA_BOX_HEIGHT = (Method.GetGlobalBillPadding() || 30) + "px";
    GLOBAL_LINE = (Method.GetGlobalBillBorder() || 1) + "px solid black";
  });

  return (
    <div ref={outputRef}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          fontFamily: GLOBAL_FONT_FAMILY,
          paddingTop: GLOBAL_PADDING,
          paddingBottom: GLOBAL_PADDING,
          border: GLOBAL_LINE,
        }}
      >
        <BillHeader billHeader={billDataProps?.billtype}></BillHeader>
        <NameDateBillNoContent
          name={
            billDataProps?.name
            // || "JAI SHANKAR TRANSPORT SERVICE AND GOOD TRAVEL LIMITED DATA JAI SHANKAR TRANSPORT SERVICE AND GOOD TRAVEL LIMITED DATA JAI SHANKAR TRANSPORT SERVICE AND GOOD TRAVEL LIMITED DATAJAI SHANKAR TRANSPORT SERVICE AND GOOD TRAVEL LIMITED DATA"
          }
          date={billDataProps?.date}
          billNo={billDataProps?.bill_no}
        ></NameDateBillNoContent>

        <BillDataContent
          dataKey={"Station From"}
          value={billDataProps?.from_station || ""}
        ></BillDataContent>

        <BillDataContent
          dataKey={"Transport"}
          value={billDataProps?.to_transport || ""}
        ></BillDataContent>

        <BillDataContent
          dataKey={"Gr. No."}
          value={
            billDataProps?.gr_no
            // || "GR4589/5825/5598/4589/1254/1205/1578/7895/4589/5825/5598/4589/1254/1205/1578/7895/4589/5825/5598/4589/1254/1205/1578/7895/4589/5825/5598/4589/1254/1205/1578/7895"
          }
        ></BillDataContent>

        <BillDataContent
          dataKey={"No. of package"}
          value={billDataProps?.no_of_package || 0}
        ></BillDataContent>

        <ParticularsAndAmountText></ParticularsAndAmountText>
        {/* Amount Section */}
        <BillDataContent
          dataKey={"Motor & Rly Freight "}
          value={billDataProps?.motor_and_rly_freight}
          isValueRightAlign={true}
        ></BillDataContent>

        <BillDataContent
          dataKey={"Commission"}
          value={billDataProps?.commission}
          isValueRightAlign={true}
        ></BillDataContent>

        <BillDataContent
          dataKey={"Labour Charges"}
          value={billDataProps?.labor_charges}
          isValueRightAlign={true}
        ></BillDataContent>

        <BillDataContent
          dataKey={"Total "}
          value={
            "Rs. " +
            (parseInt(billDataProps?.motor_and_rly_freight) +
              parseInt(billDataProps?.commission) +
              parseInt(billDataProps?.labor_charges))
          }
          isValueRightAlign={true}
          increaseValueFontSize={0.2}
        ></BillDataContent>

        <FooterContent></FooterContent>
      </div>
    </div>
  );
}

function NameDateBillNoContent({ name, billNo, date }) {
  return (
    <div
      style={{
        borderTop: GLOBAL_LINE,
        width: "100%",
        display: "flex",
      }}
    >
      <div
        style={{
          width: "50%",
        }}
      >
        <div
          style={{
            paddingLeft: GLOBAL_PADDING,
            paddingRight: GLOBAL_PADDING,
          }}
        >
          <span>
            M/s{" "}
            <span style={{ fontWeight: "bold", fontSize: "0.9rem" }}>
              {name}
            </span>{" "}
          </span>
        </div>
      </div>

      <div
        style={{
          width: "50%",
          borderLeft: GLOBAL_LINE,
        }}
      >
        {/* Bill No  */}
        <div
          style={{
            minHeight: GLOBAL_DATA_BOX_HEIGHT,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              paddingLeft: GLOBAL_PADDING,
              paddingRight: GLOBAL_PADDING,
            }}
          >
            <span>
              Bill No.{" "}
              <span style={{ fontWeight: "bold", fontSize: "0.9rem" }}>
                {billNo}
              </span>{" "}
            </span>
          </div>
        </div>

        {/* Line */}
        <div
          style={{
            width: "100%",
            borderTop: GLOBAL_LINE,
          }}
        ></div>
        {/* Date */}
        <div
          style={{
            width: "100%",
            minHeight: GLOBAL_DATA_BOX_HEIGHT,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              paddingLeft: GLOBAL_PADDING,
              paddingRight: GLOBAL_PADDING,
            }}
          >
            <span>
              Date :{" "}
              <span style={{ fontWeight: "bold", fontSize: "0.9rem" }}>
                {date}
              </span>{" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function ParticularsAndAmountText() {
  var keyContentCss = {
    paddingLeft: GLOBAL_PADDING,
    paddingRight: GLOBAL_PADDING,
    width: "50%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bolder",
    fontSize: "1rem",
  };
  var valueContentCss = {
    paddingLeft: GLOBAL_PADDING,
    paddingRight: GLOBAL_PADDING,
    width: "50%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderLeft: GLOBAL_LINE,
    fontWeight: "bolder",
    fontSize: "1rem",
  };
  return (
    <div
      style={{
        width: "calc(100%-20px)",
        display: "flex",
        justifyContent: "space-between",
        height: GLOBAL_DATA_BOX_HEIGHT,
        alignItems: "center",
        borderTop: GLOBAL_LINE,
      }}
    >
      <div style={keyContentCss}>
        <div>Particulars</div>
      </div>
      <div style={valueContentCss}>
        <div>Amount</div>
      </div>
    </div>
  );
}

function BillHeader({ billHeader }) {
  const [header, setHeader] = useState(billHeader);
  useEffect(() => {
    console.log("Header Update : ", billHeader);
    setHeader(GetHeader(billHeader));
  }, [billHeader]);

  function GetHeader(value) {
    if (!value) {
      return BILL_TYPE_KEY.CASH_MEMO.VALUE;
    }
    if (
      value === BILL_TYPE_KEY.CASH_MEMO.KEY ||
      value === BILL_TYPE_KEY.CASH_MEMO.VALUE
    ) {
      return BILL_TYPE_KEY.CASH_MEMO.VALUE;
    }
    return "Estimate";
  }
  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        paddingBottom: GLOBAL_PADDING,
      }}
    >
      <div
        style={{
          fontWeight: "bolder",
          fontSize: "1.3rem",
        }}
      >
        {header}
      </div>
      <div
        style={{
          fontWeight: "700",
          fontSize: "0.8rem",
        }}
      >
        Railway & Transport Forwarding & Commission Agents, Railway Parcel
        Office, Ajmer-305001 (M)9828051850
      </div>
    </div>
  );
}

function BillDataContent({
  dataKey,
  value,
  isKeyRightAlign,
  isValueRightAlign,
  increaseValueFontSize,
}) {
  value = value + "";
  const chunks = value.match(/.{1,30}/g);
  if (chunks) {
    value = chunks.join(" ");
  }

  var keyContentCss = {
    paddingLeft: GLOBAL_PADDING,
    paddingRight: GLOBAL_PADDING,
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: isKeyRightAlign ? "right" : "left",
    // backgroundColor:'black'
  };

  var valueContentCss = {
    paddingLeft: GLOBAL_PADDING,
    paddingRight: GLOBAL_PADDING,
    width: "50%",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: increaseValueFontSize
      ? 0.9 + increaseValueFontSize + "rem"
      : "0.9rem",
    justifyContent: isValueRightAlign ? "right" : "left",
    textOverflow: "ellipsis",
    borderLeft: GLOBAL_LINE,
    minHeight: GLOBAL_DATA_BOX_HEIGHT,
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderTop: GLOBAL_LINE,
      }}
    >
      <div style={keyContentCss}>
        <div>{dataKey}</div>
      </div>
      <div style={valueContentCss}>
        <div>{value}</div>
      </div>
    </div>
  );
}

function FooterContent() {
  const css = {
    fontWeight: "lighter",
    fontSize: "0.7rem",
    textAlign: "center",
    paddingTop: "5px",
  };
  return (
    <div
      style={{
        borderTop: GLOBAL_LINE,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={css}>
        E.&.O.E. Cash Memo - Note : If package/s are broken, please inform
        immediately.
      </div>
      <div style={css}>
        GPAY-<b>9672277142</b>
      </div>
    </div>
  );
}

// bill_output_module_data_data
var DummyBill = {
  bill_no: 78958,
  name: "Shizuka Nobisuke",
  // eslint-disable-next-line no-undef
  date: Method.TodaysDate(),
  gr_no: "GR_87945985545",
  from_station: "Jaipur",
  to_transport: "Tokyo",
  no_of_package: 10,
  motor_and_rly_freight: 1299,
  labor_charges: 4999,
  commission: 7899,
};
