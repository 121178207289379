import React, { useEffect, useState } from "react";
import "../Modules/SettingModule.css";
import { CustomToggle } from "../App";
import { Display } from "./DisplayMessage";
import CloseGrp from "../res/outline_close.png";
import { END_DATE_TYPE, Method } from "../MethodUtils";

const GLOBAL_INPUT_BOX_WIDTH = "130px";

function SettingModule({ OnModuleDisable }) {
  const [IsDeveloperOptionEnable, SetEnableDeveloperOption] = useState(() => {
    return Method.IsDeveloperOptionEnable();
  });

  const [IsDeveloperLogEnable, SetEnableDeveloperLog] = useState(() => {
    return Method.IsDeveloperLogEnable();
  });

  const [IsDeveloperWarnEnable, SetEnableDeveloperWarn] = useState(() => {
    return Method.IsDeveloperWarnEnable();
  });

  const [IsDeveloperErrorEnable, SetEnableDeveloperError] = useState(() => {
    return Method.IsDeveloperErrorEnable();
  });

  const [StartDate, SetStartDate] = useState(() => {
    const date = Method.GetStartDate();
    console.log("date : ", date);
    return date;
  });
  const [EndDate, SetEndDate] = useState(() => {
    return Method.GetEndDateType();
  });
  return (
    <div className="setting_module_container">
      <div className="setting_module_parent">
        <div className="setting_module_parent_title_div">
          <h2 className="setting_module_parent_title">Settings</h2>
          <img
            src={CloseGrp}
            alt="closeButton"
            className="setting_module_close"
            onClick={() => {
              OnModuleDisable && OnModuleDisable();
            }}
          ></img>
        </div>
        {/* Developer Option Started */}
        <ToggleGroup
          defaultToggleValue={IsDeveloperOptionEnable}
          title={"Developer Options"}
          OnUpdateToggle={(v) => {
            Method.SetDeveloperOption(v);
            SetEnableDeveloperOption(v);
          }}
        ></ToggleGroup>
        {IsDeveloperOptionEnable && (
          <ToggleGroup
            title={"Debug Log"}
            defaultToggleValue={IsDeveloperLogEnable}
            OnUpdateToggle={(v) => {
              Method.SetDeveloperLogOption(v);
              SetEnableDeveloperLog(v);
            }}
          ></ToggleGroup>
        )}
        {IsDeveloperOptionEnable && (
          <ToggleGroup
            title={"Debug Warning"}
            defaultToggleValue={IsDeveloperWarnEnable}
            OnUpdateToggle={(v) => {
              Method.SetDeveloperWarnOption(v);
              SetEnableDeveloperWarn(v);
            }}
          ></ToggleGroup>
        )}
        {IsDeveloperOptionEnable && (
          <ToggleGroup
            title={"Debug Error"}
            defaultToggleValue={IsDeveloperErrorEnable}
            OnUpdateToggle={(v) => {
              Method.SetDeveloperErrorOption(v);
              SetEnableDeveloperError(v);
            }}
          ></ToggleGroup>
        )}
        {/* Line Draw */}
        <BottomLine></BottomLine>
        {/* Developer Option Ended */}
        <div style={{ width: "100%" }}>
          <b>Auto Select Date Filter</b>
        </div>
        <div className="setting_option_child_parent">
          <div>Start Date</div>
          <input
            style={{ width: GLOBAL_INPUT_BOX_WIDTH }}
            type="date"
            value={StartDate}
            onChange={(e) => {
              SetStartDate(e.target.value);
              Method.SetStartDate(e.target.value);
            }}
          ></input>
        </div>
        <div className="setting_option_child_parent">
          <div>End Date</div>
          <select
            style={{ width: "135px", height: "20px" }}
            onChange={(e) => {
              SetEndDate(e.target.value);
              Method.SetEndDateType(e.target.value);
            }}
            value={EndDate}
          >
            <option value={END_DATE_TYPE.NONE}>None</option>
            <option value={END_DATE_TYPE.START_DATE}>Start Date</option>
            <option value={END_DATE_TYPE.TODAY}>Today Date</option>
          </select>
        </div>
        <BottomLine></BottomLine>
        <BillingOptions></BillingOptions>
      </div>
    </div>
  );
}

function BillingOptions() {
  const [contentPaddingValue, SetContentPaddingValue] = useState(() => {
    const value = parseInt(Method.GetGlobalBillPadding());
    return value || 30;
  });

  const [contentBorderSize, SetContentBorderSize] = useState(() => {
    const value = parseInt(Method.GetGlobalBillBorder());
    return value || 1;
  });

  const [contentTopBottomPadding, SetContentTopBottomPadding] = useState(() => {
    const value = parseInt(Method.GetGlobalTopBottomBillPadding());
    return value || 5;
  });

  function OnContentPaddingValue(value) {
    if (value < 0) {
      value = 0;
    }
    if (value > 3000) {
      value = 3000;
    }
    SetContentPaddingValue(value);
    Method.SetGlobalBillPadding(value);
  }

  function OnBorderSizeUpdate(value) {
    if (value < 0) {
      value = 0;
    }
    if (value > 10) {
      value = 10;
    }
    SetContentBorderSize(value);
    Method.SetGlobalBillBorder(value);
  }

  function OnTopBottomPadding(value) {
    if (value < 0) {
      value = 0;
    }
    if (value > 3000) {
      value = 3000;
    }
    SetContentTopBottomPadding(value);
    Method.SetGlobalTopBottomBillPadding(value);
  }

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <b>Billing Options</b>
      </div>
      <div className="setting_option_child_parent">
        <div>Content Padding</div>
        <input
          style={{ width: GLOBAL_INPUT_BOX_WIDTH }}
          type="number"
          value={contentPaddingValue}
          onChange={(e) => OnContentPaddingValue(e.target.value)}
        ></input>
      </div>

      <div className="setting_option_child_parent">
        <div>Top Bottom Padding</div>
        <input
          style={{ width: GLOBAL_INPUT_BOX_WIDTH }}
          type="number"
          value={contentTopBottomPadding}
          onChange={(e) => OnTopBottomPadding(e.target.value)}
        ></input>
      </div>

      <div className="setting_option_child_parent">
        <div>Border Width</div>
        <input
          style={{ width: GLOBAL_INPUT_BOX_WIDTH }}
          type="number"
          value={contentBorderSize}
          onChange={(e) => OnBorderSizeUpdate(e.target.value)}
        ></input>
      </div>
    </div>
  );
}

function BottomLine() {
  return (
    <div
      className="DRAW_LINE_HORIZONTAL_100"
      style={{ marginTop: "5px", marginBottom: "5px" }}
    ></div>
  );
}
function ToggleGroup({ defaultToggleValue, title, OnUpdateToggle }) {
  return (
    <div className="toggle_group_parent">
      <div className="toggle_name">{title}</div>
      <CustomToggle
        defaultValue={defaultToggleValue || false}
        OnToggleUpdate={(value) => {
          OnUpdateToggle && OnUpdateToggle(value);
        }}
      ></CustomToggle>
    </div>
  );
}

export default SettingModule;
