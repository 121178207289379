import { React, useState } from "react";
import SaveModule from "./SaveModule";
import { Method } from "../MethodUtils";
import SaveData from "../SaveData";
import { RequestToUpdateSingleEntries, TRANSPORT_TABLE } from "../HTTPRequest";
function SaveTransport() {
  const [saveList, setSaveList] = useState(() => {
    return SaveData.GetTransportList();
  });
  return (
    <SaveModule
      title={"Transport"}
      content_data={saveList}
      OnUpdate={(oldValue, newValue, onSuccess) => {
        console.log(oldValue, newValue);
        RequestToUpdateSingleEntries(
          `UPDATE ${TRANSPORT_TABLE.tableId} SET ${TRANSPORT_TABLE.row.table} = '${newValue}' WHERE ${TRANSPORT_TABLE.row.table} = '${oldValue}' `,
          (resp, error) => {
            if (error) {
              console.error(error);
              return;
            }
            const newArr = Method.FindAndUpdateArrayOfStrings(
              saveList,
              oldValue,
              newValue
            );
            setSaveList(newArr);
            onSuccess && onSuccess();
          }
        );
      }}
      OnDelete={(deleteValue, onSuccess) => {
        RequestToUpdateSingleEntries(
          `DELETE FROM ${TRANSPORT_TABLE.tableId} WHERE ${TRANSPORT_TABLE.row.table} = '${deleteValue}' `,
          (resp, error) => {
            if (error) {
              console.error(error);
              return;
            }
            const newArr = Method.FindAndRemoveArrayOfStrings(
              saveList,
              deleteValue
            );
            setSaveList(newArr);
            onSuccess && onSuccess(newArr);
          }
        );
      }}
    ></SaveModule>
  );
}

export default SaveTransport;
