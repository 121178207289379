import { Display } from "./Modules/DisplayMessage";

var BASE_URL = "http://127.0.0.1:32510";
// var BASE_URL = "https://muskrat-boss-seemingly.ngrok-free.app";

const DB_NAME = "bill";
const BILL_TABLE = {
  tableId: "billdata",
  row: {},
};

const CLIENT_TABLE = {
  tableId: "clientname",
  row: {
    table: "cn",
  },
};
const TRANSPORT_TABLE = {
  tableId: "transport",
  row: {
    table: "t",
  },
};
const STATION_TABLE = {
  tableId: "stationfrom",
  row: {
    table: "s",
  },
};

const RequestToLoadSingleEntries = (url, filter, callback) => {
  const _url = BASE_URL + url;
  API.POST(
    _url,
    JSON.stringify(filter),
    (resp) => callback(resp),
    (error) => callback(null, error)
  );
};

//TODO: Filter List;
/**
 * Performs an HTTP POST request to get filter bill's
 * @param {function} callback - Callback function to handel data
 */
const RequestToGetFilterBill = (filter, callback) => {
  // return;
  const url = BASE_URL + "/api/bill/filter";
  // console.log("Request Filter Is : ", filter);
  API.POST(
    url,
    JSON.stringify(filter),
    (resp) => callback(resp),
    (error) => callback(null, error)
  );
};

/**
 * Performs an HTTP GET request to fetch all bills from the API.
 * @param {function} OnSuccess - Callback function to handle successful response.
 * @param {function} OnFailed - Callback function to handle failed response.
 */
const RequestToGetAllBill = (OnSuccess, OnFailed) => {
  const url = `${BASE_URL}/api/bill/all`;
  API.GET(url, OnSuccess, OnFailed);
};

/**
 * Performs an HTTP POST request to update a bill on the API.
 * @param {object} updatedData - The updated data of the bill to be sent in the request body.
 * @param {function} OnUpdateSuccess - Callback function to handle successful response.
 * @param {function} OnUpdateFailed - Callback function to handle failed response.
 */
const RequestToUpdateBill = (updatedData, OnUpdateSuccess, OnUpdateFailed) => {
  const url = `${BASE_URL}/api/bill/update`;
  API.PUT(url, JSON.stringify(updatedData), OnUpdateSuccess, OnUpdateFailed);
};

/**
 * Performs an HTTP POST request to Create a bill on the API.
 * @param {function} OnUpdateSuccess - Callback function to handle successful response.
 * @param {function} OnUpdateFailed - Callback function to handle failed response.
 */
const RequestToCreateBill = (data, OnSuccess, OnFailed) => {
  const _url = BASE_URL + "/api/bill/create";
  console.log(data);
  const json = JSON.stringify(data);
  API.POST(_url, json, OnSuccess, OnFailed);
};
/**
 * Performs an HTTP DELETE request to Delete a bill on the API.
 * @param {function} OnUpdateSuccess - Callback function to handle successful response.
 * @param {function} OnUpdateFailed - Callback function to handle failed response.
 */
const RequestToDeleteBill = (billNo, OnSuccess, OnFailed) => {
  const _url = BASE_URL + "/api/bill/delete?bill_no=" + billNo;
  API.DELETE(_url, OnSuccess, OnFailed);
};

const RequestToAddSingleEntries = (query, callback) => {
  UpdateRowQuery(query, callback);
};
const RequestToDeleteSingleEntries = (query, callback) => {
  UpdateRowQuery(query, callback);
};
const RequestToUpdateSingleEntries = (query, callback) => {
  UpdateRowQuery(query, callback);
};
const UpdateRowQuery = (query, callback) => {
  const _url = BASE_URL + "/api/sql";
  API.POST(
    _url,
    JSON.stringify({
      db_name: DB_NAME,
      query: query,
    }),
    (data) => {
      if (data?.data[0]?.affectedRows > 0) {
        callback && callback(data);
      } else {
        callback && callback(null, "Failed to Execute query ->", data);
        console.error(data);
      }
    },
    (error) => {
      if (error) {
        console.error(error);
        callback && callback(null, error);
      } else {
        callback && callback(null, "Failed to execute query");
      }
    }
  );
};
const RequestToGetInitialValues = (callback) => {
  const _url = `${BASE_URL}/api/sql`;

  API.POST(
    _url,
    JSON.stringify({
      db_name: DB_NAME,
      querys: [
        `SELECT * FROM ${CLIENT_TABLE.tableId}`,
        `SELECT * FROM ${TRANSPORT_TABLE.tableId}`,
        `SELECT * FROM ${STATION_TABLE.tableId}`,
        `SELECT Auto_increment FROM information_schema.tables WHERE table_name = '${BILL_TABLE.tableId}'`,
      ],
    }),
    (data) => {
      const json = {
        clientNames: [],
        transportList: [],
        stationList: [],
        nextBillId: 0,
      };

      data.data.forEach((tableData) => {
        var autoIncrement = 0;
        if (tableData?.[0]?.["AUTO_INCREMENT"]) {
          autoIncrement = tableData?.[0]?.["AUTO_INCREMENT"];
        } else if (tableData?.[0]?.["Auto_increment"]) {
          autoIncrement = tableData?.[0]?.["Auto_increment"];
        }
        tableData.forEach((rowData) => {
          const clientName = rowData[CLIENT_TABLE.row.table];
          const transportName = rowData[TRANSPORT_TABLE.row.table];
          const stationName = rowData[STATION_TABLE.row.table];

          if (clientName) {
            json.clientNames.push(clientName);
          } else if (transportName) {
            json.transportList.push(transportName);
          } else if (stationName) {
            json.stationList.push(stationName);
          } else if (autoIncrement) {
            json.nextBillId = autoIncrement;
          }
        });
      });

      if (callback) {
        callback(json);
        console.log("Requested Data Is : ", data);
      }
    },
    (error) => console.error(error)
  );
};

const RequestToGetBillByDate = (startDate, endDate, callback) => {
  if (startDate === "0000-00-00" || endDate === "0000-00-00") {
    return;
  }
  const _url = BASE_URL + "/api/sql";
  API.POST(
    _url,
    JSON.stringify({
      db_name: DB_NAME,
      query: `SELECT * FROM ${BILL_TABLE.tableId} WHERE bdate BETWEEN '${startDate}' AND '${endDate}'`,
    }),
    (data) => {
      console.log(data);
      data && callback(data.data[0]);
    },
    (error) => console.error(error)
  );
};
const API = {
  GET: (url, OnSuccess, OnFailed) => {
    console.log("[ API | GET ] " + url);
    try {
      fetch(url)
        .then((resp) => {
          if (!resp.ok) {
            OnFailed && OnFailed("Network response was not ok");
          }
          return resp.json(); // Parse JSON from the response
        })
        .then((data) => {
          console.log("JSON Data:", data);
          OnSuccess(data);
        })
        .catch((error) => {
          OnFailed && OnFailed(error);
        });
    } catch (e) {
      OnFailed && OnFailed(e);
      console.error({
        url: url,
        error: e,
      });
    }
  },
  PUT: (url, data, OnSuccess, OnFailed) => {
    // console.log("[ API | PUT ] " + url, data);
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function () {
      ResponseHandler(this, OnSuccess, OnFailed);
    });
    xhr.open("PUT", url);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(data);
  },
  POST: (url, data, OnSuccess, OnFailed) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          var error = {
            status: response.status,
            message: response.statusText,
            url: url,
            data: JSON.parse(data),
          };
          Display.Error(error);
          OnFailed && OnFailed("Network response was not ok");
        }
        return response.json();
      })
      .then((resp) => {
        var message = {
          status: resp.status,
          message: resp.statusText,
          url: url,
          data: JSON.parse(data),
        };
        Display.Log(message);
        OnSuccess && OnSuccess(resp);
      })
      .catch((error) => {
        OnFailed && OnFailed(error);
      });
  },
  DELETE: (url, OnSuccess, OnFailed) => {
    console.log("[ API | DELETE ] ", url);
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function () {
      ResponseHandler(this, OnSuccess, OnFailed);
    });
    xhr.open("DELETE", url);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send();
  },
};

const ResponseHandler = (event, OnSuccess, OnFailed) => {
  if (event && event.readyState === 4) {
    if (event.status === 200) {
      OnSuccess && OnSuccess(event.responseText);
      return;
    }
    Display.Error(event.responseText);
    OnFailed && OnFailed(event.responseText);
  }
};

export {
  RequestToGetAllBill,
  RequestToUpdateBill,
  RequestToCreateBill,
  RequestToDeleteBill,
  RequestToGetInitialValues,
  RequestToGetFilterBill,
  RequestToLoadSingleEntries,
  BASE_URL,
  RequestToGetBillByDate,
  CLIENT_TABLE,
  TRANSPORT_TABLE,
  STATION_TABLE,
  RequestToAddSingleEntries,
  UpdateRowQuery,
  RequestToDeleteSingleEntries,
  RequestToUpdateSingleEntries,
};
