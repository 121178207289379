import "./App.css";
import { React, useEffect, useRef, useState } from "react";
import Select from "react-select";
import SidePanel from "./Modules/SidePanel";
import BillManagement from "./Modules/BillManagement";
import save_image from "../src/res/add.png";
import SaveClient from "./Modules/SaveClient";
import SaveTransport from "./Modules/SaveTransports";
import SaveStation from "./Modules/SaveStation";
import DisplayMessage from "./Modules/DisplayMessage";
import SettingModule from "./Modules/SettingModule";
import ExcelExport from "./Modules/ExcelExport";
import ErrorBoundary from "./ErrorBoundary";
import { RequestToGetInitialValues } from "./HTTPRequest";
import SaveData from "./SaveData";
var GenerateConfirmationPopup = null;
function App() {
  useEffect(() => {
    const timer = setTimeout(() => {
      RequestToGetInitialValues((resp) => {
        SaveData.CreateClientList(resp?.clientNames);
        SaveData.CreateTransportList(resp?.transportList);
        SaveData.CreateStationList(resp?.stationList);
        console.log("Next Bill ID :", resp?.nextBillId);
        SaveData.CreateEntryCount(resp?.nextBillId);
      });
    }, 100);
    return () => clearTimeout(timer);
  });

  return <AppDiv></AppDiv>;
}

const SAVE_MODULE = {
  NONE: 0,
  BILLING: 1,
  SAVE_CLIENT: 2,
  SAVE_FROM: 3,
  SAVE_TRANSPORT: 4,
  SETTING_PAGE: 5,
  EXCEL_EXPORT: 6,
};

const AppDiv = () => {
  const [IsBlackBackgroundEnable, setBlackBackground] = useState(false);
  const [SaveModuleEnable, SetSaveModuleEnable] = useState(SAVE_MODULE.BILLING);
  const [confirmPopupData, setConfirmPopupData] = useState({
    IsEnable: false,
    header: "No Header",
    message: "No Message",
    onAccept: () => {},
    onReject: () => {},
  });

  const UpdateBlackBackground = (enable) => {
    setBlackBackground(enable);
  };

  const ShowConfirmPopup = (header, message, onAccept, onReject) => {
    setConfirmPopupData({
      IsEnable: true,
      header: header,
      message: message,
      onAccept: onAccept,
      onReject: onReject,
    });
  };
  GenerateConfirmationPopup = ShowConfirmPopup;
  const handleAccept = () => {
    confirmPopupData.onAccept();
    disablePopup();
  };

  const handleReject = () => {
    confirmPopupData.onReject();
    disablePopup();
  };

  const disablePopup = () => {
    setConfirmPopupData({
      IsEnable: false,
      header: "No Header",
      message: "No Message",
      onAccept: () => {},
      onReject: () => {},
    });
  };
  const onModuleUpdate = (value) => {
    SetSaveModuleEnable(value);
  };
  return (
    <div className="app_main_parent">
      <ErrorBoundary>
        {SaveModuleEnable === SAVE_MODULE.EXCEL_EXPORT && (
          <ExcelExport
            onRequestToClose={() => onModuleUpdate(SAVE_MODULE.NONE)}
          ></ExcelExport>
        )}
        <DisplayMessage></DisplayMessage>
        {SaveModuleEnable === SAVE_MODULE.SETTING_PAGE && (
          <SettingModule
            OnModuleDisable={() => SetSaveModuleEnable(SAVE_MODULE.BILLING)}
          ></SettingModule>
        )}
        {confirmPopupData.IsEnable && (
          <ConfirmationPopup
            header={confirmPopupData.header}
            message={confirmPopupData.message}
            onAccept={handleAccept}
            onReject={handleReject}
          />
        )}
        <SidePanel OnClickNav={(value) => onModuleUpdate(value)}></SidePanel>
        {/* SAVE CLIENT */}
        {SaveModuleEnable === SAVE_MODULE.SAVE_CLIENT && (
          <SaveClient></SaveClient>
        )}
        {/* SAVE TRANSPORT */}
        {SaveModuleEnable === SAVE_MODULE.SAVE_TRANSPORT && (
          <SaveTransport></SaveTransport>
        )}
        {/* SAVE FROM */}
        {SaveModuleEnable === SAVE_MODULE.SAVE_FROM && (
          <SaveStation></SaveStation>
        )}

        {/* {SaveModuleEnable === SAVE_MODULE.BILLING && ( */}
        <BillManagement
          OnBackgroundEventCalled={UpdateBlackBackground}
        ></BillManagement>
        {/* )} */}

        {IsBlackBackgroundEnable && (
          <AddBlackTransparentBackground></AddBlackTransparentBackground>
        )}
      </ErrorBoundary>
    </div>
  );
};

const AddBlackTransparentBackground = () => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100vw",
        height: "100vh",
        background: "#00000050",
        zIndex: 3,
      }}
    ></div>
  );
};

const ConfirmationPopup = ({ header, message, onAccept, onReject }) => {
  return (
    <div className="confirmation_popup_container">
      <div className="confirmation_popup_parent">
        <div className="confirmation_popup_title">{header}</div>
        <div className="confirmation_popup_line"></div>
        <div className="confirmation_popup_message">{message}</div>
        <div className="confirmation_popup_options">
          <div
            className="confirmation_popup_reject"
            onClick={() => onReject && onReject()}
          >
            Cancel
          </div>

          <div
            className="confirmation_popup_accept"
            onClick={() => {
              onAccept && onAccept();
            }}
          >
            Yes
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomToggle = ({ OnToggleUpdate, defaultValue }) => {
  const [IsEnable, SetEnable] = useState(defaultValue || false);

  function HandelClick() {
    const __enable = IsEnable;
    SetEnable(!__enable);
    OnToggleUpdate && OnToggleUpdate(!__enable);
  }
  return (
    <div className="custom_toggle_button" onClick={HandelClick}>
      {IsEnable && (
        <div className="custom_toggle_enable">
          <div className="custom_toggle_enable_slider"></div>
        </div>
      )}
      {!IsEnable && (
        <div className="custom_toggle_disable">
          <div className="custom_toggle_disable_slider"></div>
        </div>
      )}
    </div>
  );
};
const __options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const CustomInput = ({
  _value,
  _placeholder,
  _onValueChange,
  suggestions,
  custom_css,
  inputType,
  _pattern,
  _readOnly,
  OnClickSaveButton,
  isSuggestionRequired,
  defaultFocus,
}) => {
  const [input_value, setInputValue] = useState(_value);
  const [___isMenuOpen, setMenuOpenState] = useState(false);
  const inputReference = useRef(null);
  const [isSaveButtonActive, setSaveButtonActive] = useState(false);
  const [__defaultFocus, setDefaultFocus] = useState(defaultFocus);

  useEffect(() => {
    if (!__defaultFocus && inputReference) {
      return;
    }
    const timer = setTimeout(() => {
      inputReference?.current?.focus();
      setDefaultFocus(false);
      console.log("hello");
    }, 0);
    return () => clearTimeout(timer);
  });

  function ConvertSuggestionToSelect(__suggestions) {
    var arr = [];
    suggestions?.map((value, index) => {
      var json = { value: value, label: value };
      arr.push(json);
    });
    return arr;
  }

  const [suggestionList, setSuggestionList] = useState(() => {
    return ConvertSuggestionToSelect(suggestions);
  });

  function updateInputValue(v) {
    v = v.toUpperCase();
    setMenuOpenState(v.length > 0);
    setInputValue(v);
  }
  const filterOption = ({ label, value }, string) => {
    return value.toLowerCase().startsWith(input_value.toLowerCase());
  };

  useEffect(() => {
    if (!suggestions || !input_value) {
      return;
    }

    const isMatch = suggestions.some((item) => item.startsWith(input_value));
    setSaveButtonActive(!isMatch);
    var json = [{ value: input_value, label: "Add - " + input_value }];
    setSuggestionList(isMatch ? ConvertSuggestionToSelect(suggestions) : json);
  }, [input_value]);
  return (
    <div className="custom_input_field_parent" style={custom_css}>
      {_placeholder && (
        <div
          style={{
            color: "#000000",
            fontWeight: "lighter",
            fontSize: "0.8rem",
          }}
        >
          {_placeholder}
        </div>
      )}
      {_readOnly && (
        <div
          style={{
            backgroundColor: "#00000010",
            height: "100%",
            width: "100%",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            outline: "1px solid black",
            paddingLeft: "2px",
            paddingRight: "2px",
            borderRadius: "1px",
            padding: "2px",
          }}
        >
          {input_value}
        </div>
      )}

      <div className="WIDTH_HEIGHT_ROW_100">
        <div className="WIDTH_HEIGHT_100">
          {!_readOnly && isSuggestionRequired && (
            <Select
              ref={inputReference}
              options={suggestionList}
              placeholder={input_value}
              onInputChange={(newValue) => {
                updateInputValue(newValue);
              }}
              onChange={(selectedOption) => {
                _onValueChange && _onValueChange(selectedOption.value);
              }}
              menuIsOpen={___isMenuOpen}
              filterOption={filterOption}
            ></Select>
          )}

          {!_readOnly && !isSuggestionRequired && (
            <input
              value={input_value}
              ref={inputReference}
              className="custom_input_field"
              placeholder={_placeholder}
              onChange={(e) => {
                if (inputType === "number") {
                  if (!e.target.value) {
                    e.target.value = 0;
                  }
                  if (e.target.value > 0) {
                    e.target.value = parseFloat(e.target.value);
                  }
                }
                setInputValue(e.target.value);
                _onValueChange && _onValueChange(e.target.value);
              }}
              type={inputType}
              pattern={_pattern}
            ></input>
          )}
        </div>

        {isSaveButtonActive && (
          <img
            src={save_image}
            alt="save"
            className="custom_input_field_save_button"
            onClick={() => {
              OnClickSaveButton && OnClickSaveButton();
              setSaveButtonActive(false);
            }}
          ></img>
        )}
      </div>
    </div>
  );
};

export { GenerateConfirmationPopup, CustomInput, SAVE_MODULE, CustomToggle };

export default App;
