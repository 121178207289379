import "./SidePanel.css";
import bill_icon from "../res/inventory_2.png";
import user_icon from "../res/user_management_cion.png";
import from_transport_icon from "../res/from_transport.png";
import to_transport_icon from "../res/to_transport.png";
import excel_icon from "../res/excel_icon.png";
import expand_icon from "../res/double_arrow.png";
import sink_icon from "../res/rev_double_arrow.png";
import setting_icon from "../res/setting.png";

import { SAVE_MODULE } from "../App";
import { useEffect, useState } from "react";

const KEY_EXPANDED = "is_expanded";

export default function SidePanel({ OnClickNav }) {
  const [IsExpanded, setPanelExpanded] = useState(() => {
    const storedValue = localStorage.getItem(KEY_EXPANDED);
    return storedValue ? JSON.parse(storedValue) : false;
  });

  const panel_css = {
    width: IsExpanded ? "150px" : "50px",
    height: "100%",
    backgroundColor: "#F1F1F1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const OnClickExpandedButton = () => {
    setPanelExpanded(!IsExpanded);
  };

  useEffect(() => {
    localStorage.setItem(KEY_EXPANDED, JSON.stringify(IsExpanded));
  }, [IsExpanded]);

  return (
    <div style={panel_css}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CustomButtonModule
          image={bill_icon}
          name={"Billing"}
          isExpanded={IsExpanded}
          OnItemClick={() => OnClickNav(SAVE_MODULE.BILLING)}
        ></CustomButtonModule>
        <CustomButtonModule
          image={user_icon}
          name={"Client"}
          isExpanded={IsExpanded}
          OnItemClick={() => OnClickNav(SAVE_MODULE.SAVE_CLIENT)}
        ></CustomButtonModule>
        <CustomButtonModule
          image={from_transport_icon}
          name={"Transport"}
          isExpanded={IsExpanded}
          OnItemClick={() => OnClickNav(SAVE_MODULE.SAVE_TRANSPORT)}
        ></CustomButtonModule>
        <CustomButtonModule
          image={to_transport_icon}
          name={"Station"}
          isExpanded={IsExpanded}
          OnItemClick={() => OnClickNav(SAVE_MODULE.SAVE_FROM)}
        ></CustomButtonModule>
        <CustomButtonModule
          image={excel_icon}
          name={"Excel"}
          isExpanded={IsExpanded}
          OnItemClick={() => OnClickNav(SAVE_MODULE.EXCEL_EXPORT)}
        ></CustomButtonModule>
        <CustomButtonModule
          image={setting_icon}
          name={"Settings"}
          isExpanded={IsExpanded}
          OnItemClick={() => OnClickNav(SAVE_MODULE.SETTING_PAGE)}
        ></CustomButtonModule>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="POINTER_HOVER"
        onClick={OnClickExpandedButton}
      >
        <img
          src={IsExpanded ? sink_icon : expand_icon}
          alt=""
          className="side_panel_button_options"
        ></img>
      </div>
    </div>
  );
}

function CustomButtonModule({ isExpanded, image, name, OnItemClick }) {
  return (
    <div
      className="POINTER_HOVER"
      style={{
        width: "100%",
        height: "50px",
        display: "flex",
        flexDirection: "row",
        alignItems: "left",
        // outline: "1px solid black",
      }}
      onClick={() => {
        OnItemClick && OnItemClick();
      }}
    >
      <img
        src={image}
        alt=""
        className="side_panel_button_options"
        style={{
          // outline: "1px solid black",
          paddingLeft: "10px",
        }}
      ></img>
      {isExpanded && (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "0.8rem",
            paddingLeft: "20px",
            // outline: "1px solid black",
          }}
        >
          {name}
        </div>
      )}
    </div>
  );
}
