import { React, useState } from "react";
import SaveModule from "./SaveModule";
import { Method } from "../MethodUtils";
import SaveData from "../SaveData";
import { CLIENT_TABLE, RequestToUpdateSingleEntries } from "../HTTPRequest";

function SaveClient() {
  const [saveList, setSaveList] = useState(() => {
    return SaveData.GetClientList();
  });
  return (
    <SaveModule
      title={"Clients"}
      content_data={saveList}
      OnUpdate={(oldValue, newValue, onSuccess) => {
        console.log(oldValue, newValue);
        RequestToUpdateSingleEntries(
          `UPDATE ${CLIENT_TABLE.tableId} SET ${CLIENT_TABLE.row.table} = '${newValue}' WHERE ${CLIENT_TABLE.row.table} = '${oldValue}' `,
          (resp, error) => {
            if (error) {
              console.error(error);
              return;
            }
            const newArr = Method.FindAndUpdateArrayOfStrings(
              saveList,
              oldValue,
              newValue
            );
            setSaveList(newArr);
            onSuccess && onSuccess();
          }
        );
      }}
      OnDelete={(deleteValue, onSuccess) => {
        RequestToUpdateSingleEntries(
          `DELETE FROM ${CLIENT_TABLE.tableId} WHERE ${CLIENT_TABLE.row.table} = '${deleteValue}' `,
          (resp, error) => {
            if (error) {
              console.error(error);
              return;
            }
            const newArr = Method.FindAndRemoveArrayOfStrings(
              saveList,
              deleteValue
            );
            setSaveList(newArr);
            onSuccess && onSuccess(newArr);
          }
        );
      }}
    ></SaveModule>
  );
}

export default SaveClient;
