import { React, useEffect, useState } from "react";
import DeleteGrp from "../res/delete.png";
import EditGrp from "../res/edit_icon.png";
import CheckGrp from "../res/outline_check.png";
import CloseGrp from "../res/outline_close.png";
import "../Modules/SaveModule.css";
import { Method } from "../MethodUtils";

function SaveModule({ title, content_data, OnDelete, OnUpdate }) {
  const [display_data, setDisplayData] = useState([]);
  const [filter_data, setFilterData] = useState("");

  useEffect(() => {
    setTimeout(() => {
      const filterArray = content_data.filter((item) =>
        item.toLowerCase().startsWith(filter_data.toLowerCase())
      );
      setDisplayData(filterArray);
    }, 100);
  }, [filter_data, content_data]);

  useEffect(() => {}, [display_data]);

  function UpdateFilterList(newList) {
    setDisplayData([]);
    setTimeout(() => {
      setDisplayData(newList);
    });
  }

  return (
    <div
      style={{
        width: "20%",
        textAlign: "center",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2>{title}</h2>

      <input
        style={{ width: "90%", marginBottom: "10px", height: "30px" }}
        placeholder="Search..."
        onChange={(e) => setFilterData(e.target.value)}
      ></input>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          overflowY: "scroll",
          paddingTop: "30px",
          height: "100%",
        }}
      >
        {display_data &&
          display_data.map((option, index) => (
            <div
              key={index} // Provide a unique key based on index
              style={{
                width: "90%",
                textAlign: "left",
                paddingTop: "2px",
                paddingBottom: "2px",
                marginBottom: "2.5px",
                marginTop: "2.5px",
                borderRadius: "2px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                height: "25px",
                padding: "5px",
              }}
            >
              {/* User is view  */}
              {
                <SaveViewModule
                  key={index} // Provide a unique key for each SaveViewModule
                  key_index={index}
                  data={option}
                  OnRequestToDeleteEntry={(deleteValue, OnSuccess) =>
                    OnDelete &&
                    OnDelete(deleteValue, (newArr) => {
                      UpdateFilterList(newArr);
                      OnSuccess && OnSuccess();
                    })
                  }
                  onRequestToUpdate={(oldValue, newValue, OnSuccess) => {
                    OnUpdate &&
                      OnUpdate(oldValue, newValue, () => {
                        console.log("update Success");
                        OnSuccess && OnSuccess();
                      });
                  }}
                ></SaveViewModule>
              }
            </div>
          ))}
      </div>
    </div>
  );
}

function SaveViewModule({
  key_index,
  data,
  OnRequestToDeleteEntry,
  onRequestToUpdate,
}) {
  const [IsEditing, SetEditingState] = useState(false);
  const [output_text, setOutputText] = useState(data);
  const [oldData, setOldData] = useState(data);
  function RequestToUpdateEntry() {
    console.log(`old -> ${oldData}, new -> ${output_text}}`);
    if (!Method.IsStringValid(output_text)) {
      return;
    }
    if (oldData === output_text) {
      SetEditingState(false);
      return;
    }
    onRequestToUpdate &&
      onRequestToUpdate(oldData, output_text, () => {
        SetEditingState(false);
        setOldData(output_text);
      });
  }

  return (
    <div
      key={key_index}
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom:'1px solid #00000050'
      }}
    >
      {IsEditing && (
        <div className="save_module_editing_content">
          <input
            className="save_module_editing_content_input"
            placeholder={output_text}
            onChange={(e) => {
              setOutputText(e.target.value);
            }}
          ></input>
          <div>
            <img
              className="POINTER_HOVER"
              src={CheckGrp}
              alt="delete"
              style={{ width: "26px", paddingRight: "6px" }}
              onClick={() => RequestToUpdateEntry()}
            ></img>
            <img
              className="POINTER_HOVER"
              src={CloseGrp}
              alt="edit"
              style={{ width: "26px", paddingRight: "6px" }}
              onClick={() => {
                setOutputText(oldData);
                SetEditingState(false);
              }}
            ></img>
          </div>
        </div>
      )}

      {/* User Is Not Editing */}
      {!IsEditing && (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {output_text}
        </div>
      )}
      {!IsEditing && (
        <div>
          <img
            className="POINTER_HOVER"
            src={EditGrp}
            alt="delete"
            style={{ width: "26px", paddingRight: "6px" }}
            onClick={() => SetEditingState(true)}
          ></img>
          <img
            className="POINTER_HOVER"
            src={DeleteGrp}
            alt="edit"
            style={{ width: "26px", paddingRight: "6px" }}
            onClick={() => {
              OnRequestToDeleteEntry &&
                OnRequestToDeleteEntry(output_text, () => {
                  SetEditingState(false);
                  setOldData(output_text);
                });
            }}
          ></img>
        </div>
      )}
    </div>
  );
}

export default SaveModule;
